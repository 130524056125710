import { onUnmounted, ref, watch } from '@vue/composition-api'
import { getIndex } from '@core/utils'
import store from '@/store'
import router from '@/router'
import itemStoreModule from '@/views/apps/rides/itemStoreModule'

export default function controller() {
  const USER_APP_STORE_MODULE_NAME = 'app-ride'

  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })
  const blankItem = {
    id: null,
    start_color: '#0C24DA',
    end_color: '#0C24DA',
    name: null,
    duration: null,
    total_rides: null,
    pick_location: null,
    drop_location: null,
    tests: [],
  }
  const form = ref()
  const items = ref([])
  const item = ref({})
  const signatureForm = ref({})
  const canEdit = (th, td) => {
    if (td && th.value && td.editable) {
      td.classes = td.classes || {}
      td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
    } else {

    }
  }
  const statuses = ref([
    { id: 0, label: 'Inactive' },
    { id: 1, label: 'Active' },
  ])

  const tableColumns = [
    {
      align: 'center',
      text: 'Id',
      value: 'id',
      width: '100px',
      filter: { place_holder: '', type: 'number' },
    },
    {
      text: 'User',
      value: 'user.name',
      fixed: true,
      width: '150px',
      editable: true,
      required: true,
      type: 'text',
      info: 'Confirm the update of "Name" ?',
      requiredMessage: 'Cart name is required',
      sm: 12,
      md: 12,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'select',
          label: 'User',
          value: 'user_id',
          items: [],
          source: '/users',
          item_text: 'name',
          item_value: 'id',
        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        source: '/users',
        label: 'name',
        id: 'id',
        multiple: true,
        items: [],
        key: 'order.user_id',
        column: 'orders.user_id',
        join_table: 'users',
        join_column: 'users.id',
        local_column: 'orders.user_id',
      },
    },
    {
      text: 'Total',
      value: 'total',
      fixed: true,
      width: '150px',
      editable: true,
      required: true,
      type: 'number',
      info: 'Confirm the update of "Total" ?',
      requiredMessage: 'Cart total is required',
      sm: 12,
      md: 12,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'number', label: 'Total', value: 'order_total', required: true,
        },
      ],
      filter: { place_holder: '', type: 'number' },
    },

    {
      text: 'Status',
      value: 'status',
      type: 'select',
      align: 'center',
      width: '200px',
      selected_value: 'status',
      selected_label: 'label',
      itemLabel: 'label',
      itemValue: 'id',
      items: statuses.value,
      comment_column: 'status',
      editable: false,
      info: 'Confirm the update of "Status" ?',
      column_name: 'status',
      fields: [
        {
          type: 'select',
          label: 'Status',
          value: 'status',
          items: statuses.value,
          item_text: 'label',
          item_value: 'id',
        },
      ],
      filter: {
        place_holder: '', type: 'select', column: 'status', values: statuses.value, label: 'label', id: 'id', multiple: false,
      },
    },
    /*
    {
      text: 'Description',
      value: 'description',
      fixed: true,
      width: '200px',
      align: 'center',
      cellClass: 'text-center',
      editable: true,
      type: 'textarea',
      rows: 3,
      info: 'Confirm the update of "Description" ?',
      fields: [
        {
          type: 'text', label: 'Description', value: 'description', required: false,
        },
      ],
      filter: { place_holder: '', type: 'text' },

      // filter: {
      //   place_holder: '',
      //   type: 'select',
      //   values: store.state.app.genres,
      //   label: 'label',
      //   id: 'id',
      //   multiple: true,
      //   column: 'rides.package_id',
      //   join_table: 'packages',
      //   join_column: 'packages.id',
      //   local_column: 'rides.genre_id',
      // },
    },*/
    {
      text: 'Created At',
      value: 'created_at',
      align: 'center',
      cellAlign: 'center',
      menu: false,
      type: 'date',
      width: '150px',
      editable: true,
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
      fields: [
        { type: 'date', label: 'Created At', value: 'created_at' },
      ],
      filter: {
        place_holder: 'Date1', type: 'date', range: true,
      },
      filters: [
        {
          key: 'created_at',
          text: 'Created At',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Is empty' },
            { id: 1, label: 'Not empty' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'ride_created_at',
          column: 'created_at',
        },
      ],
    },
    {
      text: 'Products',
      value: 'products',
      type: 'button',
      align: 'center',
      sortable: false,
    },

    {
      text: 'Actions',
      value: 'actions',
      align: 'right',
      class: 'actions',
      width: '50px',
      sortable: false,
    },
  ]
  const tableColumnsUserView = [
    ...tableColumns.filter(ele => ele.value != 'user.name'),
  ]

  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const isProductsFormActive = ref(false)
  const isPropositionActive = ref(false)
  const isRelanceActive = ref(false)
  const deleteDialog = ref(false)
  const signatureDialog = ref(false)
  const accordDialog = ref(false)
  const downloadDialog = ref(false)
  const downloadObject = ref({ loading: false, url: '' })
  const searchQuery = ref('')
  const userFilter = ref(router.currentRoute.params.id)
  const payFilter = ref(null)
  const sourceFilter = ref(null)
  const statusFilter = ref(null)
  const stateFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)
  const filters = ref([])
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const rideStates = ref({
    CREATED: 0,
    APPROVED: 1,
    SIGNED: 2,
    COLLECTED: 3,
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }
  const select = data => {
    if (data.state) {
      selectedRows.value.push(data.item)
    } else {
      selectedRows.value.splice(selectedRows.value.findIndex(ele => ele.id === data.item.id), 1)
    }
  }

  /* filters.value = userFilter.value != null ? tableColumnsUserView : tableColumns */

  // new item
  const openDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }
  const openProductsDialog = val => {
    item.value = val
    isProductsFormActive.value = !isProductsFormActive.value
  }
  const openPropositionDialog = () => {
    isPropositionActive.value = !isPropositionActive.value
  }
  const openRelanceDialog = () => {
    isRelanceActive.value = !isRelanceActive.value
  }

  const openSignatureDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    item.value = val
    signatureDialog.value = !signatureDialog.value
  }

  const openAccordDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    item.value = val
    accordDialog.value = !accordDialog.value
  }

  // fetch data
  const fetchItems = download => {
    accordDialog.value = false
    signatureDialog.value = false
    isFormActive.value = false
    isProductsFormActive.value = false
    isPropositionActive.value = false
    isRelanceActive.value = false
    downloadObject.value = { loading: true, url: null }

    const singleFilters = filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({
      ...ele.filter,
      key: ele.value,
      values: null,
    }))

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0)
    const selectedMultipleFilters = []
    for (let i = 0; i < multipleFilters.length; i++) {
      selectedMultipleFilters.push(...multipleFilters[i].filters
        .filter(ele => ele.value !== '' && ele.value != null && (ele.value.constructor !== Array || ele.value.length > 0))
        .map(ele => ({ ...ele, values: null/* , type: null */ })))
    }

    store
      .dispatch('app-cart/fetchItems', {
        /* filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })), */
        filters: [...singleFilters, ...selectedMultipleFilters],
        term: searchQuery.value,
        state: stateFilter.value,
        status: statusFilter.value,
        pay: payFilter.value,
        user: userFilter.value,
        download,
        source: sourceFilter.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        if (download) {
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const { data, total } = response.data
          items.value = data.map(ele => {
            ele.custom_discount = ele.custom_discount || false

            return ele
          })
          totalItems.value = total
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-cart/deleteItem', item.value.id)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          loading.value = false
        })
    }
  }
  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }, 500)
  }, { deep: true })
  watch([searchQuery, payFilter, sourceFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  const saved = data => {
    /* items.value[data.index] = data.item */
    fetchItems()
  }

  return {
    saved,
    rideStates,
    item,
    signatureForm,
    items,
    downloadDialog,
    downloadObject,
    filters,
    tableColumns,
    searchQuery,
    payFilter,
    sourceFilter,
    statusFilter,
    stateFilter,
    totalItems,
    loading,
    options,
    selectedRows,
    select,
    isFormActive,
    isProductsFormActive,
    isPropositionActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    signatureDialog,
    accordDialog,
    isRelanceActive,
    openRelanceDialog,
    openSignatureDialog,
    openAccordDialog,
    resetForm,
    openDialog,
    openProductsDialog,
    openPropositionDialog,
    deleteItem,
    deleteDialog,
    confirmDelete,
    fetchItems,
    statuses,
  }
}
